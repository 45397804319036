
import { Vue, Options } from 'vue-class-component';
import dayjs from 'dayjs';
import debounce from 'lodash-es/debounce';
import Calendar from 'dayjs/plugin/calendar';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import DefaultLayout from '@/lib/layouts/DefaultLayout.vue';
import { Consult, PaginatedResponse, Patient } from '@/models';
import {
  ButtonLink,
  BasePagination,
  BaseTextInput,
  DataTable,
  PopoverLink,
  BasePopover,
  BaseIcon,
  SpecialityButton,
  BaseButton,
  PopoverButton,
  LockConsultModal,
  BaseModal
} from '@/lib/components';
import { ConsultService, PatientReviewService } from '@/services/api';
import PatientSupportingDocumentModal from '@/views/patient/PatientSupportingDocumentModal.vue';
import { getDobFromISOString, getNhsNumberFromPatient } from '@/helpers/patient.helper';
import { sexOptions, genderOptions } from '@/constants';
import { TranslateResult } from 'vue-i18n/index';
import { useProgressStore } from '@/stores/progress.store';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';

dayjs.extend(localizedFormat);

@Options({
  components: {
    LockConsultModal,
    PopoverButton,
    BaseIcon,
    BaseButton,
    PopoverLink,
    SpecialityButton,
    BasePopover,
    DefaultLayout,
    ButtonLink,
    BasePagination,
    BaseTextInput,
    DataTable,
    BaseModal,
    PatientSupportingDocumentModal
  }
})
export default class ReviewListPage extends Vue {
  loading = true;
  progressStore = useProgressStore();
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();

  rows: Consult[] = [];
  perPage = 0;
  total = 0;
  search = '';
  filtered = false;
  consultService = new ConsultService();
  request: CancelTokenSource | null = null;

  isModalOpen = false;
  modalTargetPatientId = '';
  modalTargetReviewId = '';
  modalTargetLockedByUserFullName = '';

  selectedDocumentId: number | null = null;

  sexOptions = sexOptions();
  genderOptions = genderOptions();

  get page() {
    return Number(this.$route.query.page) || 1;
  }

  get sort() {
    return this.$route.query.sort || 'completed_at';
  }

  get organisationId() {
    return this.sessionStore.currentOrganisationId;
  }

  get currentUserId() {
    return this.sessionStore.currentUser.id;
  }

  created() {
    this.progressStore.startProgress();
    dayjs.extend(Calendar);
  }

  unmounted() {
    this.progressStore.removeProgress();
    if (this.request) {
      this.request.cancel();
    }
  }

  mounted() {
    this.search = String(this.$route.query.search || '');
    this.fetchConsults();
    // this.fetchStats();

    const unWatchRoute = this.$watch('$route', async (to, from) => {
      if (from.path === to.path && from.query !== to.query) {
        await this.fetchConsults();
        window.scroll({
          top: 0,
          behavior: 'smooth'
        });
      } else {
        unWatchRoute();
      }
    });

    // Re-fetch consults when switching organisation
    this.$watch('organisationId', async () => {
      this.search = '';
      if (Object.keys(this.$route.query).length) {
        await this.$router.replace({ path: this.$route.path });
      }
      await this.fetchConsults();
    });
  }

  async fetchConsults() {
    this.loading = true;

    this.request = axios.CancelToken.source();
    const requestConfig: AxiosRequestConfig = {
      params: {
        include: 'patient,clinic,clinicConfiguration,review,formSubmission',
        page: this.page,
        sort: this.sort,
        ...(this.search ? { 'filter[search]': this.search } : {}),
        ...{
          'filter[status]': 'Awaiting Review',
          'filter[owner]': this.organisationId
        }
      },
      cancelToken: this.request.token
    };

    try {
      const response = (await this.consultService.index(requestConfig)) as PaginatedResponse<Consult[]>;
      this.request = null;
      this.rows = response.data.map((consult: Consult) => {
        if (consult.patient) {
          consult.patient.nhs = getNhsNumberFromPatient(consult.patient);
        }
        return consult;
      });

      this.perPage = response.meta.per_page;
      this.total = response.meta.total;
      this.filtered = this.search.length > 0;
      this.progressStore.finishProgress();
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.progressStore.setError();
        this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.consult.fetch-error')
        });
      }
    } finally {
      this.loading = false;
    }
  }

  async updateSearch() {
    // Maintain sort order and only add search param when non-empty
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...(this.$route.query.sort ? { sort: this.$route.query.sort } : {}),
        ...(this.search ? { 'filter[search]': this.search } : {})
      }
    });
  }

  get updateSearchDebounced() {
    return debounce(() => this.updateSearch(), 500);
  }

  async changePage(page: number) {
    // Keep all existing query parameters
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...this.$route.query,
        page: page.toString()
      }
    });
  }

  async updateSort(sort: string) {
    // Keep the search if present, but always reset the page
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...(this.search ? { search: this.search } : {}),
        sort
      }
    });
  }

  formatDob(dob: string) {
    return this.$d(getDobFromISOString(dob), 'shortMonth');
  }

  getSexOrGender(patient: Patient): string | TranslateResult | undefined {
    if (this.sessionStore.currentOrganisation?.uses_gender && patient.gender) {
      const gender = this.genderOptions.find((option) => option.value === patient.gender);
      return gender ? gender.label : patient.gender;
    }
    const sex = this.sexOptions.find((option) => option.value === patient.sex);
    return sex ? sex.label : patient.sex;
  }

  clickUnlockConsult(patientId: string, reviewId: string, lockedByUserFullName: string) {
    this.modalTargetPatientId = patientId;
    this.modalTargetReviewId = reviewId;
    this.modalTargetLockedByUserFullName = lockedByUserFullName;
    this.isModalOpen = true;
  }

  async unlockConsult(patientId: string, reviewId: string) {
    const patientReviewService = new PatientReviewService(patientId);
    this.isModalOpen = false;
    await patientReviewService.unlock(reviewId);
    return this.fetchConsults();
  }

  async continueConsult(patientId: string, consultId: string, reviewId: string) {
    const patientReviewService = new PatientReviewService(patientId);
    await patientReviewService.lock(reviewId, this.sessionStore.currentUser.id);
    return await this.$router.push(`/patients/${patientId}/consults/${consultId}/image-review`);
  }

  closeModal() {
    this.modalTargetPatientId = '';
    this.modalTargetReviewId = '';
    this.modalTargetLockedByUserFullName = '';
    this.isModalOpen = false;
  }

  viewSupportingDocument(id: number) {
    this.selectedDocumentId = id;
  }

  closeSupportingDocumentModal() {
    this.selectedDocumentId = null;
  }
}
