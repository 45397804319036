import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "my-12" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "flex justify-between -mt-4 border-b" }
const _hoisted_4 = { class: "flex items-center text-lg font-bold text-gray-900" }
const _hoisted_5 = { class: "flex py-4" }
const _hoisted_6 = { class: "font-medium text-gray-900" }
const _hoisted_7 = { class: "uppercase" }
const _hoisted_8 = { class: "font-medium text-gray-900" }
const _hoisted_9 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_10 = {
  key: 0,
  class: "text-sm font-medium capitalize text-gray-500"
}
const _hoisted_11 = {
  key: 1,
  class: "text-sm font-medium capitalize text-gray-500 flex items-center"
}
const _hoisted_12 = { class: "uppercase text-gray-600 ml-1" }
const _hoisted_13 = { class: "font-medium text-gray-900" }
const _hoisted_14 = { class: "text-sm font-medium capitalize text-gray-500" }
const _hoisted_15 = { class: "font-medium text-gray-900" }
const _hoisted_16 = { class: "font-medium text-gray-900" }
const _hoisted_17 = { class: "flex flex-row items-center justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_MkBadge = _resolveComponent("MkBadge")!
  const _component_ButtonLink = _resolveComponent("ButtonLink")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_SpecialityButton = _resolveComponent("SpecialityButton")!
  const _component_PopoverLink = _resolveComponent("PopoverLink")!
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_BasePopover = _resolveComponent("BasePopover")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_BasePagination = _resolveComponent("BasePagination")!
  const _component_LockConsultModal = _resolveComponent("LockConsultModal")!
  const _component_PatientSupportingDocumentModal = _resolveComponent("PatientSupportingDocumentModal")!
  const _component_DefaultLayout = _resolveComponent("DefaultLayout")!
  const _directive_allow = _resolveDirective("allow")!

  return (_openBlock(), _createBlock(_component_DefaultLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.filtered ? _ctx.$t('platform.common.search-results') : _ctx.$t('platform.navigation.reviews')), 1),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_BaseTextInput, {
                modelValue: _ctx.search,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                placeholder: _ctx.$t('platform.common.search'),
                size: "small",
                onKeyup: _ctx.updateSearchDebounced
              }, null, 8, ["modelValue", "placeholder", "onKeyup"])
            ])
          ]),
          _createVNode(_component_DataTable, {
            loading: _ctx.loading,
            columns: [
            { name: 'last_name', label: _ctx.$t('platform.patient.patient') },
            { name: 'date_of_birth', label: _ctx.$t('platform.patient.born') },
            { name: 'patient_ids' },
            { name: 'clinic_name', label: _ctx.$t('custom.uhb.consult.clinic') },
            { name: 'completed_at', label: _ctx.$t('custom.uhb.consult.consult-date') },
            { name: 'assigned_reviewer_user_name', label: _ctx.$t('custom.uhb.review.assigned') },
            { name: 'actions', label: '', align: 'right' }
          ],
            rows: _ctx.rows,
            sort: _ctx.sort,
            class: "w-full mt-6",
            onSort: _ctx.updateSort
          }, {
            "cell.last_name": _withCtx(({ row }) => [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("span", _hoisted_7, _toDisplayString(row.patient.last_name) + ",", 1),
                _createTextVNode(" " + _toDisplayString(row.patient.first_name), 1)
              ])
            ]),
            "cell.date_of_birth": _withCtx(({ row }) => [
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.formatDob(row.patient.date_of_birth)), 1),
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.getSexOrGender(row.patient)) + " (" + _toDisplayString(_ctx.$t('platform.patient.years', { age: row.patient.age })) + ") ", 1)
            ]),
            "cell.patient_ids": _withCtx(({ row }) => [
              (row.patient_mrn)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t('platform.patient.mrn-abbreviation')) + " : " + _toDisplayString(row.patient_mrn), 1))
                : _createCommentVNode("", true),
              (row.patient.nhs)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createVNode(_component_BaseIcon, { name: "nhs-logo" }),
                    _createElementVNode("div", _hoisted_12, " : " + _toDisplayString(row.patient.nhs), 1)
                  ]))
                : _createCommentVNode("", true)
            ]),
            "cell.clinic_name": _withCtx(({ row }) => [
              _createElementVNode("div", _hoisted_13, _toDisplayString(row.clinic.name), 1),
              _createElementVNode("div", _hoisted_14, _toDisplayString(row.clinic.provider.name), 1)
            ]),
            "cell.completed_at": _withCtx(({ row }) => [
              _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.formatDob(row.completed_at)), 1)
            ]),
            "cell.assigned_reviewer_user_name": _withCtx(({ row }) => [
              _createElementVNode("div", _hoisted_16, _toDisplayString(row.assigned_reviewer_user_name), 1)
            ]),
            "cell.actions": _withCtx(({ row }) => [
              _createElementVNode("div", _hoisted_17, [
                (row.review_overdue)
                  ? (_openBlock(), _createBlock(_component_MkBadge, {
                      key: 0,
                      color: "danger",
                      class: "mr-4"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('custom.uhb.review.overdue')), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (row.review && row.review.locked_by_user_full_name && row.review.lockedBy.id !== _ctx.currentUserId)
                  ? (_openBlock(), _createBlock(_component_MkBadge, {
                      key: 1,
                      color: "gray",
                      class: "btn rounded-full flex-col justify-center"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BaseIcon, {
                          name: "lock-2",
                          position: "left",
                          style: {"width":"16px","height":"16px"}
                        }),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t('custom.uhb.review.locked-by', {
                    user: row.review.locked_by_user_full_name
                  })), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                (row.status === 'Awaiting Review')
                  ? _withDirectives((_openBlock(), _createBlock(_component_ButtonLink, {
                      key: 2,
                      to: `/patients/${row.patient.id}/consults/${row.id}/image-review`,
                      "right-icon": "chevron-right",
                      color: "ghost"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('custom.uhb.review.to-review')), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])), [
                      [_directive_allow, 'review:create']
                    ])
                  : _createCommentVNode("", true),
                (
                  row.status === 'Reviewing' &&
                    row.review &&
                    (!row.review.locked || row.review.lockedBy.id === _ctx.currentUserId)
                )
                  ? _withDirectives((_openBlock(), _createBlock(_component_BaseButton, {
                      key: 3,
                      "right-icon": "chevron-right",
                      color: "primary",
                      onClick: ($event: any) => (_ctx.continueConsult(row.patient.id, row.id, row.review.id))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.common.continue')), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])), [
                      [_directive_allow, 'review:update']
                    ])
                  : _createCommentVNode("", true),
                _createVNode(_component_BasePopover, { position: "left" }, {
                  button: _withCtx(() => [
                    _createVNode(_component_SpecialityButton, { class: "ml-3" })
                  ]),
                  menu: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createVNode(_component_PopoverLink, {
                        to: `/patients/${row.patient.id}`
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('platform.patient.view')), 1)
                        ]),
                        _: 2
                      }, 1032, ["to"]),
                      (row.review && row.review.locked && row.review.lockedBy.id !== _ctx.currentUserId)
                        ? (_openBlock(), _createBlock(_component_PopoverButton, {
                            key: 0,
                            onClick: ($event: any) => (_ctx.clickUnlockConsult(row.patient.id, row.review.id, row.review.locked_by_user_full_name))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('custom.uhb.review.unlock')), 1)
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        : _createCommentVNode("", true),
                      (row.file_attachment_id)
                        ? _withDirectives((_openBlock(), _createBlock(_component_PopoverButton, {
                            key: 1,
                            onClick: ($event: any) => (_ctx.viewSupportingDocument(row.file_attachment_id))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('custom.uhb.review.view-supporting-document')), 1)
                            ]),
                            _: 2
                          }, 1032, ["onClick"])), [
                            [_directive_allow, 'consult:read']
                          ])
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 2
                }, 1024)
              ])
            ]),
            _: 1
          }, 8, ["loading", "columns", "rows", "sort", "onSort"]),
          (_ctx.total && _ctx.total > _ctx.perPage)
            ? (_openBlock(), _createBlock(_component_BasePagination, {
                key: 0,
                "model-value": _ctx.page,
                "page-size": _ctx.perPage,
                length: _ctx.total,
                "sm-pages-limit": 5,
                "md-pages-limit": 8,
                "items-name": _ctx.$t('platform.navigation.reviews').toLowerCase(),
                "onUpdate:modelValue": _ctx.changePage
              }, null, 8, ["model-value", "page-size", "length", "items-name", "onUpdate:modelValue"]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.isModalOpen)
          ? (_openBlock(), _createBlock(_component_LockConsultModal, {
              key: 0,
              title: _ctx.$t('custom.uhb.review.unlock'),
              message: _ctx.$t('custom.uhb.review.unlock-message', { user: _ctx.modalTargetLockedByUserFullName }),
              onClose: _ctx.closeModal,
              onExit: _cache[1] || (_cache[1] = ($event: any) => (_ctx.unlockConsult(_ctx.modalTargetPatientId, _ctx.modalTargetReviewId)))
            }, null, 8, ["title", "message", "onClose"]))
          : _createCommentVNode("", true),
        (_ctx.selectedDocumentId)
          ? (_openBlock(), _createBlock(_component_PatientSupportingDocumentModal, {
              key: 1,
              "document-id": _ctx.selectedDocumentId,
              onClose: _ctx.closeSupportingDocumentModal
            }, null, 8, ["document-id", "onClose"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}